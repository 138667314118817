<template>
  <card-component>
    <div class="level">
      <div class="level-item">
        <div v-if="icon" class="level-item has-widget-icon">
          <div class="is-widget-icon">
            <b-icon :icon="icon" size="is-large" :type="type" />
          </div>
        </div>
        <div v-if="image" class="level-item has-widget-icon">
          <img :src="image"/>
        </div>

        <div class="is-widget-label">
          <p class="subtitle is-spaced">
            {{ label }}
          </p></div>
        <div class="title">
          <h3 class="title" v-if="this.number !== null">
            <growing-number :value="number" :prefix="prefix" :suffix="suffix" />
          </h3>
          <slot></slot>
        </div>
      </div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from '@/components/ui/CardComponent'
import GrowingNumber from '@/components/ui/GrowingNumber'
export default {
  name: 'CardWidget',
  components: { GrowingNumber, CardComponent },
  props: {
    icon: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    number: {
      type: Number,
      default: null
    },
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  }
}
</script>
<style scoped>
.level {
  padding-left: 10px;
  text-align:left;
}
.is-widget-label {
  display: inline-flex;
  align-items:center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.subtitle {
  padding-right: 10px;
  font-size: 16px;
}
.level-item {
  display: flex;
  align-items:center;
  justify-content: unset;
}
.level{
  display: flex;
  align-items:center;
}
div .title {
  min-width: 150px;
  font-size: 1.5rem;
}
</style>
